import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {rialURL} from '../App.js'
import { format } from 'date-fns';
import {useParams} from 'react-router-dom';
import $ from "jquery";





export default function Content() {
  const { cod_u } = useParams();
 const animatedComponents = makeAnimated();
 const history = useHistory();

 const [tot_ser,setTot_ser] = useState(); 
 const [tot_ins,setTot_ins] = useState(); 
 const [tot_tec,setTot_tec] = useState(); 
 const [tot_apl,setTot_apl] = useState(); 
 const [tot_aho,setTot_aho] = useState(); 
 const [tot_uti,setTot_uti] = useState(); 
 const [tot_gan,setTot_gan] = useState(); 



              
              
              
              
              
              

 //const [inputValue, setInputValue] = useState('');


 const [dl,setDl] = useState({});
  const showSwal = () => {
    withReactContent(Swal).fire({
      
      title: "Éxito!",
      text: "Registro Actualizado con Éxitoo",
      icon: "success"
      
    })
  } 

  const show_borrar = (event1) => {
    withReactContent(Swal).fire({
      
      title: "Eliminar fila",
      text: "¿Está Seguro de querer eliminar esta fila?",
      icon: "warning",
      confirmButtonColor: 'red',
      confirmButtonText: 'Si',
      showCancelButton: true,
      cancelButtonText: "Cancelar"
      
    });
    $('.swal2-confirm').click(function(){
      delel(event1);
  });

  } 

  const act = () => {
    withReactContent(Swal).fire({
      
      title: "Éxito!",
      text: "Recargue la página para ver los cambios",
      icon: "success"
      
    })
  }


  //Porcentajes Editables
 const [edPor,setEdPor] = useState({});
 

 const ed_por = (event) => {

   var tab = event.currentTarget.getAttribute("name");
   var value = '';
   value = event.currentTarget.value;
  //  console.log(value);
   
   var idi = event.currentTarget.id;
   setEdPor(values => ({...values, ['val']: value}));
   setEdPor(values => ({...values, ['idi']: idi}));
   setEdPor(values => ({...values, ['tab']: tab}));
   setEdPor(values => ({...values, ['codigo']: 'edPor'}));
   //showSwal();
   //console.log(idi);
   
   
 }
 
 useEffect(() => { //Muy importante usar el use efect porque es lo que afecta en tiempo real al DOM
  axios.post(rialURL+'chileinmu/api/servicios/update', edPor).then(function(response){
    //console.log(response.data);
    //history.push('/Ver');
});


}, [edPor]); 

const editar_c = (event) => {
  const cual = event.target.id;
  var element = document.getElementsByClassName('lal'+cual);
  var element2 = document.getElementsByClassName('lel'+cual);
  var element3 = document.getElementsByClassName('ed'+cual);
  var tr = document.getElementsByClassName('tr'+cual);

  tr[0].classList.add("editando");
 

    for(var i=0; i< element.length; i++){
      element[i].classList.remove("lol");
      element[i].classList.remove("lol");
      
  }

  for(var i=0; i< element2.length; i++){
    element2[i].classList.add("lol");
    element2[i].classList.add("lol");
    
}

for(var i=0; i< element3.length; i++){
  element3[i].classList.remove("btng");
  element3[i].classList.add("btny");
  
}

}




const yii = (event) => {
 var v = event.currentTarget.value;
 window.location.replace("/Ver/"+v);
  //showSwal();
  //console.log(idi);
  
  
}







//Función Para el valor del servicio. 
  const [v_t,setV_t] = useState({});
  const valor = (event) => {
    
    var tab = event.currentTarget.getAttribute("name");
    var value = '';
    // var p_tec = document.getElementById("p_tec").value
    // var p_apli = document.getElementById("p_apli").value
    // var p_uti = document.getElementById("p_uti").value

    var p_tec = document.getElementsByName("p_tec")[0].value;
    var p_apli = document.getElementsByName("p_apli")[0].value
    var p_uti = document.getElementsByName("p_uti")[0].value
    var p_aho = document.getElementsByName("p_aho")[0].value
    var p_uti2 = document.getElementsByName("p_uti2")[0].value

    value = event.currentTarget.value; ; //event.currentTarget.textContent
    var idi = event.currentTarget.id;

    var tec = (value*p_tec)/100;
    var apli = (value*p_apli)/100;
    var uti = (value*p_uti)/100;
    var aho = (value*p_aho)/100;
    var uti2 = (value*p_uti2)/100;
    
   

    const element = document.getElementById(idi);
    element.getElementsByClassName("tec")[0].textContent = tec;//Al Tecnico
    element.getElementsByClassName("apli")[0].textContent = apli;//Al Aplicador
    element.getElementsByClassName("uti")[0].textContent = uti;//Al Utilidad
    element.getElementsByClassName("aho")[0].textContent = aho;//Al Ahorro
    element.getElementsByClassName("uti2")[0].textContent = uti2;//A la Ganancia
    
    setV_t(values => ({...values, ['val']: value}));
    setV_t(values => ({...values, ['idi']: idi}));
    setV_t(values => ({...values, ['tab']: tab}));
    setV_t(values => ({...values, ['codigo']: 'edV_t'}));
    setV_t(values => ({...values, ['tec']: tec}));
    setV_t(values => ({...values, ['apli']: apli}));
    setV_t(values => ({...values, ['uti']: uti}));
    setV_t(values => ({...values, ['aho']: aho}));
    setV_t(values => ({...values, ['uti2']: uti2}));
    act();
    //console.log(idi);
    
   //setTot_ser(0);
    
    
  }

















  

  useEffect(() => { //Muy importante usar el use efect porque es lo que afecta en tiempo real al DOM
    //console.log(edif);
    
  
            //http://localhost:80/chileinmu/api/servicios/update
              //https://appc.codecland.com/api/servicios/ver/
    axios.post(rialURL+'chileinmu/api/servicios/update', v_t).then(function(response){
      //console.log(response.data);
      //history.push('/Ver');
  });
  
  
  }, [v_t]); 





//  const [fr,setFR] = useState({});

//Todo esto es para hacer los inputs editables
 const [edif,setEdif] = useState({});
 const [servi,setServi] = useState([]); //este es un array con la tabla de servicios
 const [tot,setTot] = useState([]); //este es un array con el total de la economia


 const handleChange = (event) => {
  var colorP = 'no';
   var tab = event.currentTarget.getAttribute("name");
   var value = '';
   var color = 'gray';
   
   if(tab =='frecuencia' || tab =='cliente' || tab =='fecha' || tab =='nota' || tab =='estado' || tab =='pago' || tab =='valor_insumo'){ //Esto permite que el combobox. Pueda editar información. 
    value = event.currentTarget.value;
   }else{
    value = event.currentTarget.textContent;
   }
   
   var idi = event.currentTarget.id;
   var element = document.getElementById(idi);
   if(value=='Pendiente'){
    //console.log(value);
    document.getElementById(idi).classList.add('Pendiente');
    color = 'red';
   }else if(tab=='estado'){
    document.getElementById(idi).classList.remove('Pendiente');
    
   }

   if(value=='Realizado'){
    console.log(value);
    document.getElementById(idi).classList.add('Realizado');
    color = 'green';
   }else if(tab=='estado'){
    document.getElementById(idi).classList.remove('Realizado');
    
   }

   if(value == 'Cancelado'){
    document.getElementById(idi).classList.add('Cancelado');
   }else{
    document.getElementById(idi).classList.remove('Cancelado');
   }


   if(String(value)=='Si'){
    
    event.currentTarget.classList.add("si");
    event.currentTarget.classList.remove("nos");
    colorP = 'si';
   }else if(String(value)=='No') {
    event.currentTarget.classList.add('nos');
    event.currentTarget.classList.remove("si");
    colorP = 'nos';
    
   } else if(String(value)=='0' || String(value)=='No, Trimestral'){
    event.currentTarget.classList.remove("si");
    event.currentTarget.classList.remove("nos");
    colorP = 'no';
    console.log(value);
   }


   setEdif(values => ({...values, ['color']: color}));
   setEdif(values => ({...values, ['colorP']: colorP}));
   setEdif(values => ({...values, ['val']: value}));
   setEdif(values => ({...values, ['idi']: idi}));
   setEdif(values => ({...values, ['tab']: tab}));
   setEdif(values => ({...values, ['codigo']: 'edDT'}));
   //showSwal();
   //console.log(idi);
   
   
 }
 
 useEffect(() => { //Muy importante usar el use efect porque es lo que afecta en tiempo real al DOM
  //console.log(edif);

          //http://localhost:80/chileinmu/api/servicios/update
            //https://appc.codecland.com/api/servicios/ver/
  axios.post(rialURL+'chileinmu/api/servicios/update', edif).then(function(response){
    //console.log(response.data);
    //history.push('/Ver');
});


}, [edif]); 





const [l,setl] = useState([]); //Con esto se obtienen. Las frecuencias como un objeto Jason de los servicios 





//Con esto se obtiene la tabla de servicios y toda la info que carga junto con la Pagina
  useEffect(() => {
    getServicios(); 
    getNo();
    getSin();
    getProx();
    getPor()

    servi.map((tot, key) => { 
      let hh = 0;
     hh += parseInt(tot.aplicador);
      // set_apli(hh);
      console.log(hh);
    });

  }, []);
  var t_apli = 0;
  const [t_aplii,set_apli] = useState();
  function getServicios() {
    
             //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
             //https://appc.codecland.com/api/servicios/ver/
    axios.get(rialURL+"chileinmu/api/servicios/ver?cod=ver1&year="+cod_u).then(function (response) {
        console.log(response.data);
        setServi(response.data);
        
        



        //history.push('/');
        setl(response.data) ;

        /*response.data.map((el) => (
          console.log(el.id)
        ));*/



      });
  }

  function getTotal() {
  
axios.get(rialURL+"chileinmu/api/servicios/ver?cod=getTot&year="+cod_u).then(function (response) {
console.log(response.data);
setTot(response.data); 

setTot_ser(new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(parseInt(response.data[0].tot_ser)));
setTot_ins(new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(parseInt(response.data[0].tot_ins)));
setTot_tec(new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(parseInt(response.data[0].tot_tec)));
setTot_apl(new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(parseInt(response.data[0].tot_apl)));
setTot_aho(new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(parseInt(response.data[0].tot_aho)));
setTot_uti(new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(parseInt(response.data[0].tot_uti)));
setTot_gan(new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(parseInt(response.data[0].tot_gan)));

});
}
















//Tarjetas de Estadística Rápida

//Proximo Servicio
const [prox_c,setProx_c] = useState();
const [prox_f,setProx_f] = useState();
var prox = [];
var pcli;
function getProx() {
 
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=getProx").then(function (response) {
    if(String(response.data)!=''){
    prox['fecha']= response.data[0].fecha;

    //Sacamos de el Nombre del cliente  Desde el array. 
    prox['cliente']= response.data[0].cliente; 
    pcli = JSON.parse(prox['cliente']);
    prox['cliente']=pcli[0].value
    
    //lo pasamos para mostrar
    setProx_c(prox['cliente']);
    setProx_f(format(prox['fecha'].replace(/:00/g,''), 'dd/MM/yyy kk:mm'));
    
    //console.log(prox['cliente']);
  }
//history.push('/');
//setl(response.data) ;

/*response.data.map((el) => (
console.log(el.id)
));*/

  });

}


  //Servicios sin Pagar
const [sin,setSin] = useState();
function getSin() {
 
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=getSin").then(function (response) {
    console.log(response.data[0].sin);
    setSin(response.data[0].sin);
//history.push('/');
//setl(response.data) ;

/*response.data.map((el) => (
console.log(el.id)
));*/

  });

}

  //Trabajos Pendientes
const [pendi,setPendi] = useState();
function getNo() {
 
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=getNo").then(function (response) {
    console.log(response.data[0].pendientes);
    setPendi(response.data[0].pendientes);
//history.push('/');
//setl(response.data) ;

/*response.data.map((el) => (
console.log(el.id)
));*/

  });

}






  
//Borrar Row. 
  const delel = (event) => {
    const cual = event.target.id;

    setDl(values => ({...values, ['cual']: cual}));
    setDl(values => ({...values, ['codigo']: 'Dl'}));
    //showSwal();
    window.location.reload();
  }

  useEffect(() => { //Esto evita que no envíe la primera información
  axios.post(rialURL+'chileinmu/api/servicios/borrar', dl).then(function(response){
        console.log(response.data);
    
        
      });
  
  }, [dl]); 




//Optener Porcentajes
const [por,setPor] = useState([]);
function getPor() {
  
           
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=por1").then(function (response) {
      console.log(response.data);
      setPor(response.data);
      //history.push('/');
    });
}


//Combobox de Personas
const [per,setPer] = useState([]);
function getPer() {
  
           //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
           //https://appc.codecland.com/api/servicios/ver/
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=per1").then(function (response) {
      console.log(response.data);
      setPer(response.data);
      //history.push('/');
    });
}




//Combobox de Servicios
const [ser,setSer] = useState([]);
function getSer() {
  
           //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
           //https://appc.codecland.com/api/servicios/ver/
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=ser1").then(function (response) {
      console.log(response.data);
      setSer(response.data);
      //history.push('/');
    });
}






// UseEffect para todos los Combobox
const [client,setClient] = useState([]);
useEffect(() => {
  getClient();
  getSer();
  getPer();
  getTotal();
 // console.log(servi);
}, []);

//Combobox de Clientes
function getClient() {
  
           //http://localhost:80/chileinmu/api/servicios/ver?cod=ver1
           //https://appc.codecland.com/api/servicios/ver/
  axios.get(rialURL+"chileinmu/api/servicios/ver?cod=cli1").then(function (response) {
      console.log(response.data);
      setClient(response.data);
      //history.push('/');
      
    });
}




//Opciones Del combobox Frecuencia. 
var optionsi = [{value: "Mensual", label:"Mensual", cod: "fr", od: "15"},{value: "Semanal", label:"Semanal" , cod: "fr", od: "15"}];
var options= [];

options['Mensual'] = {value: "Mensual", label:"Mensual", cod: "fr"};
options['Semanal'] = {value: "Semanal", label:"Semanal", cod: "fr"};


function chns (a){
  
  //console.log(a);
  var ch = a;
    axios.post(rialURL+'chileinmu/api/servicios/actualizar', ch).then(function(response){
    console.log(response.data);
  });
  console.log(ch);
  
  

}

const Component = () => {
 
  return (
    <div>
      <img src="dist/img/approved.gif" alt="Exito" style={{width: 48, height: 48}} />
        
    </div>
  );
};









  return (
    <div id="sidd" className="content-wrapper">
      <div className="card-body">
        <div className="row cc">
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{prox_c}</h3>
                <p>Próximo Servicio: {prox_f}</p>
              </div>
              <div className="icon">
                {/* <i className="ion ion-bag" /> */}
              </div>
              <a href="#" className="small-box-footer">
               <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-success">
              <div className="inner">
                <h3>
                  {sin}
                </h3>
                <p>Servicios <strong>No Pagados</strong> </p>
              </div>
              <div className="icon">
                {/* <i className="ion ion-stats-bars" /> */}
              </div>
              <a href="#" className="small-box-footer">
                 <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>
          {/* ./col */}

          {/* ./col */}
          <div className="col-lg-3 col-6">
            {/* small box */}
            <div className="small-box bg-danger">
              <div className="inner">
                <h3>{pendi}</h3>
                <p>Servicios <strong>Pendientes</strong> </p>
              </div>
              <div className="icon">
                {/* <i className="ion ion-pie-graph" /> */}
                
              </div>
              <a href="#" className="small-box-footer">
                 <i className="fas fa-arrow-circles-right" />
              </a>
            </div>
          </div>
          {/* ./col */}
        </div>
      </div>

      <section className="content">
        <div className="card">
          
        <div className="card-header " style={{display:"inline-block"}}>
        
          <div style={{textAlign:"center"}}><h1>Tabla Servicios Año: {cod_u}</h1></div>
          
        
          
        </div>
        <div className="card-body">


        <table id="example3" className="table table-striped cabesa">
            <thead className="">
              <tr className="hstiky">
              <th></th>
                <th>Frecuencia</th>
                <th>Cliente</th>
                <th>Fecha</th>
                <th>Servicio</th>
                <th>Personas</th>
                <th>Notas</th>
                <th>Estado</th>
                <th>Pago</th>
                <th>Valor</th>
                <th>Valor Insumo</th>
                <th><div className="porc">&ensp;Tecnico {por.map((po, key) =><input key={key} type="tel" className="form-control" defaultValue={po.p_tec} onChange={ed_por} name="p_tec" id="1"  />)}<span>%&ensp;</span></div></th>
                <th><div className="porc">&ensp;Aplicador {por.map((po, key) =><input key={key} type="tel" className="form-control" defaultValue={po.p_apli} onChange={ed_por} name="p_apli" id="1"  />)}<span>%&ensp;</span></div></th>
                <th><div className="porc">&ensp;Ahorro {por.map((po, key) =><input key={key} type="tel" className="form-control" defaultValue={po.p_aho} onChange={ed_por} name="p_aho" id="1"  />)}<span>%&ensp;</span></div></th>
                <th><div className="porc">&ensp;ChileInmune {por.map((po, key) =><input key={key} type="tel" className="form-control" defaultValue={po.p_uti} onChange={ed_por} name="p_uti" id="1"  />)}<span>%&ensp;</span></div></th>
                <th><div className="porc">&ensp;Utilidad {por.map((po, key) =><input key={key} type="tel" className="form-control" defaultValue={po.p_uti2} onChange={ed_por} name="p_uti2" id="1"  />)}<span>%&ensp;</span></div></th>
                <th>Creador</th>
                <th></th>
              </tr>
            </thead>
            {/* <textarea type="text" className="form-control inpDT" name="n_completo" id={user.id} defaultValue={user.n_completo}  placeholder="" onBlur={handleChange}/>  optionsi.concat({ ididi: user.id})*/}
            <tbody id="tbodyx" className="noborder">
              
            {servi.map((user, key) =>
              <tr className={user.estado+" tr"+user.id} id ={user.id} key={key}>
                <td><td><i id={user.id} onClick={editar_c} class={"btn btn-primary btng fa-regular fa-pen-to-square ed"+user.id}></i></td></td>
                
                <td><div className={"lol2 form-control lel"+user.id}>{user.frecuencia}</div><div className={"lol lal"+user.id}><select  class="form-control selc sombra" defaultValue={user.frecuencia} name="frecuencia" id={user.id} onChange={handleChange} >
                           <option value="0">Seleccione Frecuencia</option>
                           <option value="Puntual">Puntual</option>
                           <option value="Mensual">Mensual</option>
                           <option value="Semestral">Semestral</option>
                           <option value="Trimestral">Trimestral</option>
                           <option value="Anual">Anual</option>

                         </select><a>{user.cliente}</a></div></td>
                
                <td><div className={"lol2 lel"+user.id}><Select isClearable={false} isDisabled={true} maxMenuHeight={130} autoFocus={false} openMenuOnFocus={false} className="mult sombra" components={animatedComponents}  closeMenuOnSelect={false} defaultValue={JSON.parse(l[key].cliente)} options={
                 client.map((cli, key) =>{
                  return{
                    cod:'fr1',
                    tip: 'cliente',
                    idix: user.id,
                    value: cli.nombre,
                    label: cli.nombre
                  };
                 }).concat({
                  cod:'fr1',
                  tip: 'cliente',
                  idix: user.id,
                  value: 'Sin Registro',
                  label: 'Sin Registro'
                })}  isMulti/></div>
                  
                  <div className={"lol lal"+user.id}><Select isClearable={false} maxMenuHeight={130} autoFocus={false} openMenuOnFocus={false} className="mult sombra" components={animatedComponents}  closeMenuOnSelect={false} defaultValue={JSON.parse(l[key].cliente)} options={
                 client.map((cli, key) =>{
                  return{
                    cod:'fr1',
                    tip: 'cliente',
                    idix: user.id,
                    value: cli.nombre,
                    label: cli.nombre
                  };
                 }).concat({
                  cod:'fr1',
                  tip: 'cliente',
                  idix: user.id,
                  value: 'Sin Registro',
                  label: 'Sin Registro'
                })} onChange={chns} isMulti/><a>{user.cliente}</a></div></td>
                
                <td><div className={"lol2 lel"+user.id}><input className="form-control pami inl sombra" type="datetime-local" defaultValue={user.fecha} name="fecha" id={user.id}  readOnly={true}/></div><div className={"lol lal"+user.id}><input className="form-control pami inl sombra" type="datetime-local" defaultValue={user.fecha} name="fecha" id={user.id} onBlur={handleChange} /><a>{user.fecha}</a></div></td>

                <td><div className={"lol2 lel"+user.id}><Select isClearable={false} isDisabled={true} maxMenuHeight={130} className="mult sombra" openMenuOnFocus={false} components={animatedComponents}  closeMenuOnSelect={false} defaultValue={JSON.parse(l[key].servicio)} options={
                 ser.map((s, key) =>{
                  return{
                    cod:'fr1',
                    tip: 'servicio',
                    idix: user.id,
                    value: s.servicio,
                    label: s.servicio
                  };
                 }).concat({
                  cod:'fr1',
                  tip: 'servicio',
                  idix: user.id,
                  value: 'Sin Registro',
                  label: 'Sin Registro'
                })} isMulti/></div>
                
                
                
                <div className={"lol lal"+user.id}><Select isClearable={false} maxMenuHeight={130} className="mult sombra" openMenuOnFocus={false} components={animatedComponents}  closeMenuOnSelect={false} defaultValue={JSON.parse(l[key].servicio)} options={
                 ser.map((s, key) =>{
                  return{
                    cod:'fr1',
                    tip: 'servicio',
                    idix: user.id,
                    value: s.servicio,
                    label: s.servicio
                  };
                 }).concat({
                  cod:'fr1',
                  tip: 'servicio',
                  idix: user.id,
                  value: 'Sin Registro',
                  label: 'Sin Registro'
                })} onChange={chns} isMulti/><a>{user.servicios}</a></div></td>

                <td>
                <div className={"lol2 lel"+user.id}><Select isClearable={false} isDisabled={true} maxMenuHeight={130} className="mult sombra" components={animatedComponents}  closeMenuOnSelect={false} defaultValue={JSON.parse(l[key].personas)} options={
                 per.map((p, key) =>{
                  return{
                    cod:'fr1',
                    tip: 'personas',
                    idix: user.id,
                    value: p.nombre,
                    label: p.nombre
                  };
                 }).concat({
                  cod:'fr1',
                  tip: 'personas',
                  idix: user.id,
                  value: 'Sin Registro',
                  label: 'Sin Registro'
                })} isMulti/></div>
                  
                  
                  
                  
                  
                  
                  <div className={"lol lal"+user.id}><Select isClearable={false} maxMenuHeight={130} className="mult sombra" components={animatedComponents}  closeMenuOnSelect={false} defaultValue={JSON.parse(l[key].personas)} options={
                 per.map((p, key) =>{
                  return{
                    cod:'fr1',
                    tip: 'personas',
                    idix: user.id,
                    value: p.nombre,
                    label: p.nombre
                  };
                 }).concat({
                  cod:'fr1',
                  tip: 'personas',
                  idix: user.id,
                  value: 'Sin Registro',
                  label: 'Sin Registro'
                })} onChange={chns} isMulti/><a>{user.personas}</a></div></td>

                <td><div className={"lol2 lel"+user.id}>{user.nota}</div> <div className={"lol lal"+user.id}><textarea className="form-control pamis sombra" name="nota" id={user.id} onBlur={handleChange}>{user.nota}</textarea></div></td>
                
                <td><div className={"lol2 form-control lel"+user.id}>{user.estado}</div> <div className={"lol lal"+user.id}><select  class="form-control selc sombra" defaultValue={user.estado} name="estado" id={user.id} onChange={handleChange} >
                           <option value="0">Seleccione Estado</option>
                           <option value="Realizado">Realizado</option>
                           <option value="Pendiente">Pendiente</option>
                           <option value="Cancelado">Cancelado</option>
                           

                         </select><a>{user.estado}</a></div></td>

                <td><div className={"lol2 form-control lel"+user.id}>{user.pago}</div> <div className={"lol lal"+user.id}><select className={user.colorP+" "+"form-control selc sombra pago"} defaultValue={user.pago} name="pago" id={user.id} onChange={handleChange} >
                           <option value="">Seleccione Pago</option>
                           <option value="Si">Si</option>
                           <option value="No">No</option>
                           <option value="No, Trimestral">No, Trimestral</option>

                         </select><a>{user.pago}</a> </div></td>
                         
                <td><input type="tel" className="form-control tel sombra" defaultValue={new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.valor_servicio)} name="valor_servicio" id={user.id} onBlur={valor} /></td>         
                
                <td><input type="tel" className="form-control tel sombra" defaultValue={new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.valor_insumo)} name="valor_insumo" id={user.id} onBlur={handleChange} /></td>
                
                <td><span contenteditable="false" className="form-control pami tec sombra" name="tecnico" id={user.id}  >{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.tecnico)}</span></td>
                
                <td><span contenteditable="false" className="form-control pami apli sombra" name="aplicador" id={user.id}  >{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.aplicador)}</span></td>
                
                <td><span contenteditable="false" className="form-control pami aho sombra" name="ahorro" id={user.id}  >{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.ahorro)}</span></td>
                
                <td><span contenteditable="false" className="form-control pami uti sombra" name="utilidad" id={user.id}  >{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.utilidad)}</span></td>
                
                <td><span contenteditable="false" className="form-control pami uti2 sombra" name="ganancia" id={user.id}  >{new Intl.NumberFormat('es-CL', {currency: 'CLP'}).format(user.ganancia)}</span></td>
                
                <td><span contenteditable="false" className="form-control pami sombra" name="creador" id={user.id} >{user.creador}</span></td>
               
                <td><i id={user.id} value ="Borrar" onClick={show_borrar} class="fa-solid fa-trash-can btn btn-primary btnr"></i></td>
                
              </tr>
              )}
                
              </tbody>
              <tfoot className="">
              <tr className="hstiky">
              <th></th>
              <th>Frecuencia</th>
              <th>Cliente</th>
              <th>Fecha</th>
              <th>Servicio</th>
              <th>Personas</th>
              <th>Notas</th>
              <th>Estado</th>
              <th>Pago</th>
              <th>{tot_ser}</th>
              <th>{tot_ins}</th>
              <th>{tot_tec}</th>
              <th>{tot_apl}</th>
              <th>{tot_aho}</th>
              <th>{tot_uti}</th>
              <th>{tot_gan}</th>
              <th>Creador</th>
              <th></th>
            </tr>
              {/* {tot.map((p, key) =>
              <tr className="hstiky">
              <th>Frecuencia</th>
              <th>Cliente</th>
              <th>Fecha</th>
              <th>Servicio</th>
              <th>Personas</th>
              <th>Notas</th>
              <th>Estado</th>
              <th>Pago</th>
              <th>{tota}</th>
              <th>{p.tot_ins}</th>
              <th>{p.tot_tec}</th>
              <th>{p.tot_apl}</th>
              <th>{p.tot_aho}</th>
              <th>{p.tot_uti}</th>
              <th>{p.tot_gan}</th>
              <th>Creador</th>
              <th></th>
            </tr>
                )} */}
              
            </tfoot>
            
          </table>
        </div>
        </div>
      </section>
      
    </div>
    
    
  );

  
}